.signupConfirmVideo {
  display: flex;
}
.signupConfirmHidden {
  display: none;
  transform: translateX(100%);
}
.raisesignup {
  display: flex;
  position: fixed;
  z-index: 10000;
}
.hidesignup {
  display: flex;
  position: fixed;
  z-index: 10000;
}
.showsignup {
  display: flex;
  position: fixed;
  z-index: 100;
  transform: translateY(100%);
}
.signupvideo {
  display: flex;
  position: fixed;
  height: auto;
  max-height: 100%;
  max-width: 100%;
}
.playpausebutton {
  display: flex;
  position: fixed;
  bottom: 30px;
  right: 30px;
  border-radius: 30px;
  background-color: white;
  color: #333;
  padding: 10px;
  justify-content: center;
  align-items: center;
}
.playpausebuttonhidden {
  display: flex;
  position: fixed;
  bottom: 30px;
  right: 30px;
  border-radius: 30px;
  background-color: white;
  color: #333;
  padding: 10px;
  justify-content: center;
  align-items: center;
  transform: translateY(100%);
}
.closesignupvideobutton {
  display: flex;
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 30px;
  background-color: white;
  color: #333;
  padding: 10px;
}
.closesignupvideobuttonhidden {
  display: flex;
  position: fixed;
  bottom: -830px;
  left: 50%;
  transform: translate(-50%, 100%);
  border-radius: 30px;
  background-color: white;
  color: #333;
  padding: 10px;
}
.videoscroller {
  display: flex;
  position: fixed;
  flex-direction: column;
  bottom: 30px;
  left: 30px;
  height: 250px;
  width: 30px;
  border-radius: 35px;
  justify-content: flex-end;
  align-items: flex-end;
  background-color: white;
  color: #333;
  padding: 10px;
  justify-content: center;
  align-items: center;
}
.videoscrollerhidden {
  display: flex;
  position: fixed;
  flex-direction: column;
  left: 30px;
  height: 250px;
  width: 30px;
  border-radius: 35px;
  justify-content: flex-end;
  align-items: flex-end;
  background-color: white;
  color: #333;
  padding: 10px;
  justify-content: center;
  align-items: center;
  transform: translateY(200%);
}
.signUpPushToplay {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  z-index: 10;
  height: 100vh;
  width: 100vw;
  background-color: rgba(51, 51, 51, 0.473);
}
.signUpPushToplayhidden {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  z-index: 10;
  height: 100vh;
  width: 100vw;
  background-color: rgba(51, 51, 51, 0.473);
  transform: translateY(100%);
}
.alternativephoto {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  background-size: cover;
}
.alternativephotohidden {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -101;
  transform: translateY(100%);
  background-size: cover;
}
.signbackground-video {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 10;
  transform: translateX(-50%) translateY(-50%);
  background-size: cover;
}
.login {
  display: flex;
  position: fixed;
  flex-direction: column;
  z-index: 9999;
  top: 5px;
  height: 99%;
  width: 100%;
  color: white;
  font-size: 26px;
  overflow: auto;
  z-index: 1;
}
.form1 {
  display: flex;
  position: relative;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  top: 5px;
  width: 99%;
  max-width: 500px;
  background-color: rgba(0, 0, 0, 0.868);
  border-radius: 45px;
  border: #333 1px solid;
  padding: 30px 0px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9998;
}
.input-field {
  height: 36px;
  width: 60vw;
  max-width: 260px;
  margin: 2px 0px;
  color: black;
  font-size: 20px;
}
.input-fielddark {
  height: 36px;
  width: 60vw;
  max-width: 260px;
  margin: 2px 0px;
  color: black;
  background-color: #777;
  font-size: 20px;
}
.numberdropdown {
  color: black;
}
.loginsignup {
  display: flex;
  font-size: 44px;
  padding-top: 20px;
}
.loginsentence {
  display: flex;
  position: relative;
  font-size: 26px;
  padding-top: 10px;
}
.loginwarning {
  display: flex;
  position: relative;
  flex-direction: column;
  font-size: 16px;
  color: rgb(199, 199, 199);
  padding: 0px 5%;
  padding-top: 10px;
  margin-bottom: 5px;
}
.loginwarning div {
  color: white;
  font-weight: bold;
  font-size: 16px;
}
.loginbtn {
  display: grid;
  border: #333 1px solid;
  justify-self: center;
  background-color: white;
  width: 140px;
  height: 56px;
  font-size: 26px;
  border-radius: 45px;
}
.previewbtn {
  display: flex;
  position: relative;
  margin-top: 5vh;
  border: #333 1px solid;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  width: 140px;
  height: 56px;
  font-size: 26px;
  border-radius: 45px;
  padding: 10px;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-height: 600px) {
  .previewbtn {
    display: none;
  }
  .form1 {
    margin-bottom: 10px;
  }
  .showrecaptcha {
    margin-bottom: 10px;
  }
  .showphonecodeform {
    margin-bottom: 10px;
  }
}
video {
  position: fixed;
  height: 100%;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  z-index: -100;
  background-size: cover;
}
.alternative {
  position: fixed;
  z-index: -101;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  background-size: cover;
} /*
.bigdot{
  display:flex;
  position:fixed;
  font-size:50px;
  color:white;
  left:30px;
  bottom:20px;

}*/
.showphonecodeform {
  display: flex;
  position: relative;
  flex-direction: row;
  max-width: 300px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 45px;
  border: #333 1px solid;
  padding: 30px;
  margin-bottom: 80px;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
}
.hiderecaptcha {
  display: none;
}
.showrecaptcha {
  display: grid;
  position: relative;
  margin-top: 5vh;
  justify-content: center;
  align-items: center;
  top: 0px;
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 45px;
  border: #333 1px solid;
  margin-bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
}
.showphonecodeformbtn {
  background-color: rgb(0, 102, 255);
  border-radius: 30px;
  color: white;
  font-size: 16px;
}
.showphonecodeforminput {
  background-color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 30px;
  font-size: 16px;
}
.phonecodeinput {
  color: #111;
  font-size: 16px;
}
.phonecodeinput::placeholder {
  color: #777;
  font-size: 16px;
}
.openSignupVid {
  display: flex;
  position: fixed;
  top: 30px;
  right: 10px;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 45px;
  border: #333 1px solid;
}
.plusrightexit {
  display: flex;
  position: fixed;
  right: 10px;
  bottom: 30px;
  color: white;
  font-size: 30px;
  background-color: #333;
  padding: 15px;
  padding-top: 5px;
  border-radius: 30px;
  z-index: 9999;
}
@media (min-height: 600px) {
  .plusrightexit {
    display: none;
  }
}
.spaceforphone {
  margin-top: 0px;
}
