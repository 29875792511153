.fullcal_backdrop {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 6000;
  top: 0;
  bottom: 0;
  right: 0;
}
.closecal {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 26px;
  padding-bottom: 20px;
  text-align: center;
  flex-direction: column;
}

.monthlyl {
  margin-top: 10px;
  cursor: pointer;
  justify-self: left;
  align-self: flex-end;
  font-size: 26px;
  padding-bottom: 20px;
  margin-left: 20px;
  text-align: center;
}
.monthlyr {
  margin-top: 10px;
  cursor: pointer;
  justify-self: right;
  align-self: flex-end;
  font-size: 26px;
  padding-bottom: 20px;
  margin-right: 20px;
  text-align: center;
  max-width: 25vw;
}
.monthlyskip {
  display: flex;
  position: relative;
  width: 100%;
  border: 1px white solid;
  bottom: 0px;
  position: relative;
  justify-content: space-between;
}
.back {
  position: fixed;
  height: 40px;
  width: 40px;
  z-index: 1;
  padding: 9px;
}
.gototoday {
  position: fixed;
  right: 4%;
  top: 0.5%;
  height: 20px;
  z-index: 1;
  margin-top: 7px;
  padding: 10px;
  font-size: 20px;
  color: #777;
  border-radius: 10%;
}
.clockdialcal {
  display: flex;
  position: fixed;
  height: 50px;
  width: 50px;
  max-width: 12vw;
  max-height: 12vw;
}
.clockdialcaltop {
  display: flex;
  position: fixed;
  height: 6vw;
  max-height: 6vh;
}

.clockbordercal {
  display: flex;
  position: fixed;
  height: 50px;
  width: 50px;
  max-width: 12vw;
  max-height: 12vw;
  z-index: -100;
}
.clockbordercaltop {
  display: flex;
  position: fixed;
  height: 6vw;
  max-height: 6vh;
  z-index: -100;
}

.CalendarContainer {
  border-radius: 5px;
  overflow: hidden;
  background-image: radial-gradient(rgba(255, 0, 0, 0), rgba(0, 0, 0, 0.878));
}

.CalendarHeader {
  justify-content: space-between;
}

.CalendarGrid {
  max-width: 500px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  font-size: 26px;
}

.CalendarMonth {
  color: rgb(206, 206, 206);
  text-align: center;
  padding: 0.5em 0.25em;
  word-spacing: 5px;
  user-select: none;
  font-size: 26px;
}
.CalendarMonthFuture {
  color: rgb(126, 166, 126);
  text-align: center;
  padding: 0.5em 0.25em;
  word-spacing: 5px;
  user-select: none;
  font-size: 26px;
}
.CalendarMonthOff {
  color: rgb(126, 126, 166);
  text-align: center;
  padding: 0.5em 0.25em;
  word-spacing: 5px;
  user-select: none;
  font-size: 26px;
}
/*
.CalendarCell {
  text-align: center;
  align-self: center;
  font-size:26px;
  height: 9.5vw;
  user-select: none;
  grid-column:12vw;
  /*grid-column: ${props => (props.index % 7) + 1} / span 1;
}*/

.CalendarWeekday {
  align-self: center;
  color: rgba(98, 123, 131, 0.878);
  border-top: 2px solid rgba(98, 123, 131, 0.878);
  border-bottom: 2px solid rgba(98, 123, 131, 0.878);
  font-size: 15px;
  text-align: center;
  /*border-right: ${props => (props.index % 7) + 1 === 7 ? `none` : `2px solid #06c`};*/
}

.PrevPostDateNumber {
  display: flex;
  cursor: pointer;
  height: 50px;
  width: 50px;
  max-width: 12vw;
  max-height: 12vw;
  color: rgba(98, 123, 131, 0.878);
  transition: all 0.1s ease-out;
  font-size: 22px;
  /*text-align:center;*/
  align-items: center;
  justify-content: center;
  border: 1px solid #222;
  transition: 0.3s ease-out;
}
.CalendarDateNumber {
  display: flex;
  cursor: pointer;
  height: 50px;
  width: 50px;
  max-width: 12vw;
  max-height: 12vw;
  color: #c0c0c0c2;
  transition: all 0.1s ease-out;
  font-size: 22px;
  /*text-align:center;*/
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(112, 152, 167, 0.429);
  background-image: radial-gradient(
    rgba(255, 0, 0, 0),
    rgba(11, 57, 104, 0.374)
  );
  transition: 0.3s ease-in;
}
/*.CalendarDateNumber:hover {
  color: white;
}*/
/*.PrevPostDateNumber:hover{
  color: white;
}*/
.CalendarDateTop {
  display: flex;
  color: white;
  position: absolute;
  /*text-align:center;*/
  align-items: center;
  justify-content: center;
  height: 3vw;
  max-height: 3vh;
  border: 1px solid #888;
  font-size: 22px;
  transition: 0.3s ease-in;
}
.HighlightedCalendarDate {
  display: flex;
  color: white;
  position: relative;
  /*text-align:center;*/
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  max-width: 12vw;
  max-height: 12vw;
  border: 1px solid #888;
  font-size: 22px;
  transition: 0.3s ease-in;
} /*
.HighlightedCalendarDate::before {
  content: "";
  position: absolute;
}*/
.TodayCalendarDate {
  display: flex;
  color: white;
  position: relative;
  /*background: transparent;
  text-align:center;*/
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  max-width: 12vw;
  max-height: 12vw;
  border: 1px solid #222;
  font-size: 22px;

  background-image: radial-gradient(
    rgba(255, 0, 0, 0),
    rgba(18, 200, 250, 0.483)
  );
  transition: 0.3s ease-in;
} /*
.TodayCalendarDate::after {
  content: "";
  position: absolute;
}*/

.containplans {
  display: flex;
  position: asbolute;
  z-index: 9999;
  max-width: 120px;
}
.containplanscal {
  display: flex;
  position: absolute;
  height: 50px;
  width: 50px;
  max-width: 12vw;
  max-height: 12vw;
  z-index: 9999;
}
.containplanscaltop {
  display: flex;
  position: absolute;
  height: 50px;
  width: 50px;
  max-width: 12vw;
  max-height: 12vw;
  z-index: 9999;
}

.placePlansOnCal {
  display: flex;
  position: absolute;
  height: 50px;
  width: 50px;
  max-width: 12vw;
  max-height: 12vw;
  border: 1px solid white;
}
.square {
  position: relative;
  width: 40%;
}
@media (max-width: 300px) {
  .square {
    position: relative;
    width: 10%;
  }
}
.square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.squaretop {
  position: relative;
  width: 40%;
}
@media (max-width: 300px) {
  .squaretop {
    position: relative;
    width: 10%;
  }
}
.squaretop:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  z-index: 9999;
  transition: ease-in;
}

@media (max-width: 600px) {
  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    z-index: 9999;
    transition: ease-in;
  }
}
@media (max-width: 300px) {
  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    z-index: 9999;
    transition: ease-in;
  }
}
